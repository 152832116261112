<template>
  <div class="container position-relative">
    <div class="w-md-85 w-lg-75 text-center mx-md-auto mb-5">
      <h2>{{ $t('OW01HomePage.ApiSuiteSection.Title') }}</h2>
      <p>{{ $t('OW01HomePage.ApiSuiteSection.Description') }}</p>
    </div>

    <div class="oah-suite-bg-1 pb-4 pb-lg-6">
      <div class="max-w-33rem w-85 mx-auto text-center">
        <router-link
          :to="{ path: `/${$route.params.lang}/api-portal`}"
          class="card card-frame shadow h-100 text-center"
        >
          <div class="card-body px-2 py-3">
            <figure class="avatar mx-auto mb-1">
              <img src="@/assets/svg/icons/icon-44.svg" class="avatar-img">
            </figure>
            <h5 class="mb-0">{{ $t('OW01HomePage.ApiSuiteSection.ApiPortal') }}</h5>
          </div>
        </router-link>
      </div>
    </div>

    <div class="oah-suite-bg-2 pb-1 pb-md-3 pb-lg-4 pb-xl-5 mx-auto">
      <div class="w-xl-90 mx-auto">
        <div class="row no-gutters">
          <div v-for="(item, index) in OahOverviewCard" :key="index" class="col-6 col-sm-4 col-lg-2 px-1 mb-2 mb-lg-0">
            <router-link
              :to="{ path: `/${$route.params.lang}/${item.url}`}"
              class="card card-frame shadow h-100 text-center"
            >
              <div class="card-body px-2 py-3">
                <figure class="avatar mx-auto mb-2">
                  <img :src="require(`@/assets/svg/icons/${item.icon}.svg`)" class="avatar-img">
                </figure>
                <h5 class="mb-0">{{ $t(item.title) }}</h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="oah-suite-bg-3 pt-7 pb-9 bg-img-hero-center">
      <div class="max-w-33rem w-85 mx-auto text-center">
        <router-link :to="{ path: `/${$route.params.lang}/api-hub-community`}" class="card bg-secondary shadow h-100 text-center" @click.native="scrollToId('hub')">
          <div class="card-body px-2 py-3">
            <figure class="avatar mx-auto mb-3">
              <img src="@/assets/svg/icons/icon-52.svg" class="avatar-img">
            </figure>
            <h5 class="mb-0 text-white">{{ $t('OW01HomePage.ApiSuiteSection.Hub') }}</h5>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OahOverview',
  data() {
    return {
      OahOverviewCard: [
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiManagement',
          icon: 'icon-24',
          url: 'api-management'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.Subscription',
          icon: 'icon-73',
          url: 'api-management'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiTransformations',
          icon: 'icon-3',
          url: 'api-transformations'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiMonetization',
          icon: 'icon-5',
          url: 'api-monetization'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiOps',
          icon: 'icon-71',
          url: 'apiops-cicd'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.Trust',
          icon: 'icon-47',
          url: 'trust-certification'
        }
      ]
    }
  },
  methods: {
    scrollToId(id) {
      document.getElementById(id) ? document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' }) : null
    }
  }
}
</script>

  <style lang="scss" scoped>
  .oah-suite-bg-1 {
      background-image: url('../assets/svg/components/oah-suite/bg-1.svg');
      background-position: 0 100%;
      background-size: auto;
      background-repeat: repeat-x;
  }

  .oah-suite-bg-2 {
      background-image: url('../assets/svg/components/oah-suite/bg-2.svg');
      background-position: 0 100%;
      background-size: auto;
      background-repeat: repeat-x;
  }

  .oah-suite-bg-3 {
    background-image: url('../assets/svg/components/oah-suite/bg-3.svg')
  }

  @media (max-width: 576px) {
    .oah-suite-bg-1 {
      background-image: url('../assets/svg/components/oah-suite/bg-1-mobile.svg');
    }
  }

  @media (max-width: 992px) {
    .oah-suite-bg-3 {
      background-image: url('../assets/svg/components/oah-suite/bg-3-mobile.svg');
    }
  }
  </style>

