// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/components/oah-suite/bg-1.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/svg/components/oah-suite/bg-2.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/svg/components/oah-suite/bg-3.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/svg/components/oah-suite/bg-1-mobile.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/svg/components/oah-suite/bg-3-mobile.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".oah-suite-bg-1[data-v-73055c39]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.oah-suite-bg-1[data-v-73055c39],.oah-suite-bg-2[data-v-73055c39]{background-position:0 100%;background-size:auto;background-repeat:repeat-x}.oah-suite-bg-2[data-v-73055c39]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.oah-suite-bg-3[data-v-73055c39]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@media (max-width:576px){.oah-suite-bg-1[data-v-73055c39]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}}@media (max-width:992px){.oah-suite-bg-3[data-v-73055c39]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}}", ""]);
// Exports
module.exports = exports;
