<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('APIManagementPage.HowItWorksSection.Subtitle') }}</span>
        <h2>{{ $t('APIManagementPage.HowItWorksSection.Title') }}</h2>
        <p>{{ $t('APIManagementPage.HowItWorksSection.Description') }}</p>
      </div>
      <!-- Video -->
      <div class="w-md-65 w-lg-50 mx-auto mb-9 mb-md-5">
        <div data-gtm-att="ga-intro-video-card" class="card shadow overflow-hidden mx-lg-auto bg-light">
          <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
            <img class="img-fluid video-player-preview transition-3d-hover" :src="require('@/assets/img/academy/' + `${ $t('AcademyPage.VideoSection.WhatIsOAH.ImageArrow') }` + '.jpg')">
            <!-- <div class="video-player-btn video-player-centered">
              <span class="video-player-icon video-player-icon-pill">
                <i class="fas fa-play-circle mr-2 font-size-2" /> {{ $t('OW01HomePage.HeroSection.WatchButton') }}
              </span>
            </div> -->
            <div class="embed-responsive embed-responsive-16by9">
              <div id="oahVideoIframe" />
            </div>
          </a>
        </div>
        <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
          <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="videoModalTitle" class="modal-title" />
                <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </button>
              </div>
              <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                <div style="padding:52% 0 0 0;position:relative;">
                  <iframe :src="$t('AcademyPage.VideoSection.WhatIsOAH.Video')" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="$t('AcademyPage.VideoSection.WhatIsOAH.Title')" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIManagementPage.HowItWorksSection.Feature1Title') }}</h3>
          <p>{{ $t('APIManagementPage.HowItWorksSection.Feature1Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-1.svg" alt="Choose API Portal Setting">
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIManagementPage.HowItWorksSection.Feature2Title') }}</h3>
          <p>{{ $t('APIManagementPage.HowItWorksSection.Feature2Description') }}</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-2.svg" alt="Define API Access Rights">
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIManagementPage.HowItWorksSection.Feature3Title') }}</h3>
          <p>{{ $t('APIManagementPage.HowItWorksSection.Feature3Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-3.svg" alt="Manage API Subscriptions">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'HowItWorks',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
