<template>
  <div>
    <div class="bg-light">
      <div id="ctaSection" class="container space-2 space-xl-3 cta-bg">
        <div class="text-center w-md-60 mx-auto">
          <h2 class="mb-3">{{ $t('APIManagementPage.ExploreSection.Title') }}</h2>
          <p class="mb-4">{{ $t('APIManagementPage.ExploreSection.Description') }}</p>
          <a id="ga-OW02-07-03" class="btn btn-primary transition-3d-hover" href="https://www.benovelty.com/contact" target="_blank">{{ $t('APIManagementPage.ExploreSection.TalkToUsButton') }}</a>
        </div>
      </div>
      <!-- SVG Background Shape -->
      <!-- <figure class="w-100 position-absolute bottom-0 left-0">
        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
      </figure> -->
    <!-- End SVG Background Shape -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BnCTA'
}
</script>

<style lang="scss" scoped>
// :style="{ background: `url(${require(`@/assets/svg/components/isometric-lego.svg`)}) center no-repeat`}"
</style>
